import React, { createContext, useState, useEffect } from "react";
import axios from 'axios';
import { config } from "./environment";

const UserContext: React.Context<any> = createContext([{}]);
UserContext.displayName = 'UserContext';

const getUser = () => axios.get(`${config.apiUrl}/user`, { withCredentials: true }).then((response) => response.data).catch(error => null);

const UserContextProvider = ({children}: any) => {
    const [state, setState] = useState({
        status: 'pending',
        error: null,
        user: null
    });

    // getUser().then((user: any) => {
    //     console.log(user)
    // }).catch((error) => console.log("error", error));

    useEffect(() => {
        getUser().then((user: any) => setState({
            status: 'success',
            error: null,
            user
        })).catch((error) => setState({
            status: 'error',
            error,
            user: null
        }));
    }, []);

    return <UserContext.Provider value={[state, setState]}>{children}</UserContext.Provider>
};

export {
    UserContext,
    UserContextProvider
};
