import React, { useContext, useState } from 'react';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import { convertToRaw } from 'draft-js';
import {
	Editor,
	createEditorState
} from 'medium-draft';
import mediumDraftImporter from 'medium-draft/lib/importer';
import mediumDraftExporter from 'medium-draft/lib/exporter';
import 'medium-draft/lib/index.css';

import { LoginDialog } from './LoginDialog';
import { config } from './environment';

import './App.scss';
import './EnterCompetition.scss';
import useAxios from 'axios-hooks';
import { ProductTile } from './ProductTile';
import { Loading } from './Loading';
import { UserContext } from './UserContext';

export function EnterCompetition() {
	const match = useRouteMatch('/competition/:competitionId/enter');
	const [{ data, loading, error }, refetch] = useAxios(
        `${config.apiUrl}/competition/${(match?.params as any).competitionId}`
	);
	const history = useHistory();

	const [{user}] = useContext(UserContext);
	const [title, setTitle] = useState('');
	const [isDragOver, setIsDragOver] = useState(false);
	const [featuredImage, doSetFeaturedImage] = useState(undefined as any);
	const [reader] = useState(new FileReader());
	const [shouldShowLoginDialog, setShouldShowLoginDialog] = useState(false);
	const [editorState, setEditorState] = useState(createEditorState(convertToRaw(mediumDraftImporter('')))); // loaded html goes instead of '', otherwise createEditorState() is enough
	const [submitInProgress, setSubmitInProgress] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	
	const refsEditor = React.createRef();
	const fileInput = React.createRef<any>();

    const now = new Date();
    const dateEntriesStart = data && data.dateEntriesStart ? new Date(data.dateEntriesStart) : now;
    const dateEntriesEnd = data && data.dateEntriesEnd ? new Date(data.dateEntriesEnd) : now;
    const dateVotingStarts = data && data.dateVotingStarts ? new Date(data.dateVotingStarts) : now;
    const dateVotingEnds = data && data.dateVotingEnds ? new Date(data.dateVotingEnds) : now;

    const votingInProgress = dateVotingStarts < now && dateVotingEnds > now;
    const entriesInProgress = dateEntriesStart < now && dateEntriesEnd > now;
    const isUpcoming = dateEntriesStart > now;
	const ended = dateVotingEnds < now;
	
	const setFeaturedImage = (file: File | null) => {
		doSetFeaturedImage(file);
		if (file) {
			reader.readAsBinaryString(file);
		};
	};

	const onFeaturedImageDrop = (event: React.DragEvent<HTMLLabelElement>) => {
		event.preventDefault();
		setIsDragOver(false);

		if (event.dataTransfer.items) {
			// Use DataTransferItemList interface to access the file(s)
			for (let i = 0; i < event.dataTransfer.items.length; i++) {
				// If dropped items aren't files, reject them
				if (event.dataTransfer.items[i].kind === 'file') {
					const file = event.dataTransfer.items[i].getAsFile();
					if (file) {
						setFeaturedImage(file);
						// we only take first file but we loop in case other `kind`s are dropped
						break;
					}
				}
			}
		} else {
			// Use DataTransfer interface to access the file(s)
			setFeaturedImage(event.dataTransfer.files[0]);
		}
	}

	const isReadyToSubmit = () => (
		reader.result
		&& reader.readyState === FileReader.DONE
		&& title
		&& title.length
		&& editorState.getCurrentContent().hasText()
		&& entriesInProgress
		&& !submitInProgress
		&& !submitted
	);

	const submit = () => {
		// TODO check file size, warn user and don't upload if > 50mb (max allowed on backend)
		if (!reader.result || reader.readyState !== FileReader.DONE) {
			// TODO something smart
			console.log("TODO tell the user something or prevent this from happening");
			return;
		}

		setSubmitInProgress(true);
		
		axios.post(`${config.apiUrl}/competition/${(match?.params as any).competitionId}/entry`, {
			title,
			description: mediumDraftExporter(editorState.getCurrentContent()),
			featuredImage: btoa(reader.result as string)
		}).then((res) => {
			setSubmitInProgress(false);
			if (res.data && res.data.success) {
				setSubmitted(true);
			}
        }).catch((error) => {
			console.log("caught", error.response);
			setSubmitInProgress(false);
			if (error.response.status === 401) {
				setShouldShowLoginDialog(true);
				return;
			}
		});
	};

	const clearAllFields = () => {
		setTitle('');
		setFeaturedImage(null);
		setEditorState(createEditorState());
		setSubmitted(false);
	};

	// TODO eventually - Editor sideButtons is where the add picture in the text, embeds, etc go.
	return (
		<>
			<section className='competition-entry'>
				{
					!entriesInProgress && data
					&& <div style={{textAlign: 'center', marginBottom: '30px'}}>
						<span style={{fontWeight: 'bold'}}>Entries for <i>"{data.name}"</i> are currently closed.</span>
						{
							votingInProgress
							? <><br /><br /><Link className='button-vote' to={`/competition/${(match?.params as any).competitionId}/vote`}>Participate by voting!</Link></>
							: <><br /><br /><Link className='button-vote' to={`/competitions`}>See other competitions!</Link></>
						}
					</div>
				}
				<h2>Your Entry</h2>
				<label
					htmlFor="featured-image"
					className={`featured-image ${isDragOver ? 'drag-over' : ''}`}
					onDrop={onFeaturedImageDrop}
					onDragOver={event => { setIsDragOver(true); event.preventDefault(); }}
					onDragLeave={() => setIsDragOver(false)}>
					{
						featuredImage
							? <img
								className='featured-image-preview'
								src={URL.createObjectURL(featuredImage)}
								onLoad={(event: any) => URL.revokeObjectURL(event.target.src)} />
							: !isDragOver && <div>Select featured image</div>

					}
					{
						isDragOver
						&& <div>Drop to {featuredImage ? 'replace' : 'select'} image</div>
					}
				</label>
				<input
					ref={fileInput}
					type='file'
					id='featured-image'
					name='featured-image'
					accept='image/png, image/jpeg'
					style={{ display: 'none' }}
					onChange={event => setFeaturedImage(event.target.files ? event.target.files[0] : null)} />

				<input
					type='text'
					value={title}
					className='entry-title'
					placeholder='Your entry title'
					onChange={event => setTitle(event.target.value)} />

				<Editor
					ref={refsEditor}
					editorState={editorState}
					onChange={setEditorState}
					className='entry-editor'
					placeholder='Your entry description. Select text to change its formatting.'
					sideButtons={[]} />
				{
					!isReadyToSubmit() && !submitInProgress && !submitted
					&& <p>
						* Entry requires a featured image, a title, and a description.
					</p>
				}
				{
					submitted
					&& <>
						<p>
							Thank you for your entry, {user.name}! Good luck in the competition!
						</p>
						<p>
							We'll let you know when the voting starts!
						</p>
						<p>
							For now, you can <Link to={`/competition/${(match?.params as any).competitionId}`}>check out the competition</Link> or submit another entry!
						</p>
						<button
						className='button-submit'
						onClick={clearAllFields}>
							Submit another
						</button>
					</>
				}
				<button
				disabled={!isReadyToSubmit()}
				className='button-submit'
				onClick={submit}>
					{
						submitInProgress
						? <Loading />
						: 'Submit'
					}
				</button>
				{/* <div>{mediumDraftExporter(editorState.getCurrentContent())}</div> */}
				<ProductTile />
			</section>
			<LoginDialog open={shouldShowLoginDialog} setOpen={setShouldShowLoginDialog} />
		</>
	);
}
