import React, { useContext, useState } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Link,
	Redirect
} from 'react-router-dom';
import './App.scss';
import { Battle } from './Battle';
import { Competition } from './Competition';
import { Competitions } from './Competitions';
import { VerifyEmail } from './VerifyEmail';
import { EnterCompetition } from './EnterCompetition';
import { UserContextProvider, UserContext } from './UserContext';
import { LoginDialog } from './LoginDialog';
import { UserProfile } from './UserProfile';

const LoginMenu = () => {
	const [{user}] = useContext(UserContext);
	const [shouldShowLoginDialog, setShouldShowLoginDialog] = useState(false);

	return <>
		<li>
			{
				user
				? <Link to='/user/me' className="site-nav__link">{user.name}</Link>
				: <button className='button-link' onClick={() => setShouldShowLoginDialog(true)}>Log in</button>
			}
		</li>
		<LoginDialog open={shouldShowLoginDialog} setOpen={setShouldShowLoginDialog} />
	</>;
};

const App = () => {
	return (
		<Router>
			<UserContextProvider>
				<div className="App">
					<header className="header-bar">
					</header>
					<h1 className=".site-header__logo">
						<div className="logo__image-wrapper">
							<a href="https://www.foodiespicks.com/">
								<img
									className="logo__image"
									alt="Foodies' Picks logo"
									src="//cdn.shopify.com/s/files/1/0368/4266/8076/files/foodies-picks-logo-s_0317ef41-bcc3-466b-9b93-86dfa02dd646_150x150.png?v=1586534131" />
							</a>
						</div>
					</h1>

					<nav>
						<ul className="site-nav">
							<li className="site-nav--active">
								<a href="https://www.foodiespicks.com" className="site-nav__link">
									Home
								</a>
							</li>
							<li>
								<Link to="/competitions" className="site-nav__link">
									Competitions
								</Link>
							</li>
							<li>
								<a href="https://www.foodiespicks.com/pages/contact" className="site-nav__link">
									Contact Us
								</a>
							</li>
							<LoginMenu />
						</ul>
					</nav>

					<Switch>
						<Route path="/verify-email/:verification" component={VerifyEmail} />
						<Route path={[
							"/competition/:competitionId/vote/:entryId",
							"/competition/:competitionId/vote"
						]} component={Battle} />
						<Route path="/competition/:competitionId/enter" component={EnterCompetition} />
						<Route path="/competition/:competitionId" component={Competition} />
						<Route path="/competitions" component={Competitions} />
						<Route path="/user/me" component={UserProfile} />
						<Route exact path="/"><Redirect to="/competitions" /></Route>
					</Switch>
				</div>
			</UserContextProvider>
		</Router>
	);
}

export default App;
