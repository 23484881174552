import React, { useState } from 'react';
import useAxios from 'axios-hooks';
import './App.scss';
import './Competition.scss';

import { config } from "./environment";
import { useRouteMatch, Link } from 'react-router-dom';
import { ProductTile } from './ProductTile';

export function Competitions() {
    const match = useRouteMatch("/competitions");
    const [{ data, loading, error }, refetch] = useAxios(
        `${config.apiUrl}${match?.url}`
    );
    const [descriptionsExpanded, setDescriptionsExpanded] = useState({} as any);

    const descriptionExpanded = (competition: any) => !!descriptionsExpanded[competition.id];
    const setDescriptionExpanded = (competition: any, expanded = true) => setDescriptionsExpanded({
        ...descriptionsExpanded,
        [competition.id]: expanded
    });

    const now = new Date();
    const toDate = (date: string) => date ? new Date(date) : now;
    const entriesInProgress = (item: any) => toDate(item.dateEntriesStart) < now && toDate(item.dateEntriesEnd) > now;
    const votingInProgress = (item: any) => toDate(item.dateVotingStarts) < now && toDate(item.dateVotingEnds) > now;

    return (
        <>
            {data && data.map((competition: any) =>
                <div key={competition.id}>
                    <section className='competition'>
                        {data && <>
                            <h1><Link to={`competition/${competition.id}`}>{competition.name}</Link></h1>
                            <div className='competition-dates'>
                                Entries: {toDate(competition.dateEntriesStart).toDateString()} - {toDate(competition.dateEntriesEnd).toDateString()}<br />
                                Voting: {toDate(competition.dateVotingStarts).toDateString()} - {toDate(competition.dateVotingEnds).toDateString()}
                            </div>
                            {entriesInProgress(competition) &&
                                <Link className='button-enter' to={`/competition/${competition.id}/enter`}>
                                    Enter the competition!
                                </Link>
                            }
                            {votingInProgress(competition) &&
                                <Link className='button-vote' to={`/competition/${competition.id}/vote`}>
                                    Vote!
                                </Link>
                            }
                            <div className={`description-wrapper${descriptionExpanded(competition) ? ' expanded' : ''}`}>
                                <div dangerouslySetInnerHTML={{ __html: competition.description }} />
                                <button className='show-more' onClick={() => setDescriptionExpanded(competition, !descriptionExpanded(competition))}>
                                    Show {descriptionExpanded(competition) ? 'less' : 'more'}
                                </button>
                            </div>
                            <ProductTile />
                        </>
                        }
                    </section>
                </div>
            )}
        </>
    );
}