import React, { useEffect } from 'react';
import useAxios from 'axios-hooks';
import { config } from './environment';

import './ProductTile.scss';

export const ProductTile = ({ showSectionTitle = true }: any) => {
    const [{ data: randomProduct}, refetchRandomProduct] = useAxios('random-product');
	
	useEffect(() => {
		const circleFeaturedProducts = () => {
			setTimeout(() => {
				refetchRandomProduct();
				circleFeaturedProducts();
			}, 60000);
		};
		circleFeaturedProducts();
    }, []);
    
    return randomProduct
    ? <>
        <a className='product-tile' href={randomProduct.link}>
            <div className='image-wrapper'><img src={`${config.backendUrl}/product-images/680/680/${randomProduct.image}`} /></div>
            <div className='product-tile-content'>
                <h2>{randomProduct.name}</h2>
                <div
                    className={`description`}
                    dangerouslySetInnerHTML={{ __html: randomProduct.description }} />
            </div>
        </a>
        {
            showSectionTitle
            && <p style={{textAlign: 'center', fontStyle: 'italic', fontSize: '14px'}}>Featured Product</p>
        }
    </>
    : null
};
