import React, { useEffect, useState } from 'react';
import './App.scss';
import './VerifyEmail.scss';

import { config } from "./environment";
import { useRouteMatch, Link } from 'react-router-dom';
import axios from 'axios';
import { ProductTile } from './ProductTile';

export function VerifyEmail() {
	const match = useRouteMatch("/verify-email/:verification");
	
	const [user, setUser]: any[] = useState();
	const [error, setError]: any[] = useState();

	useEffect(() => {
		axios.post(`${config.apiUrl}/verify-email`, {verification: (match?.params as any).verification}).then((res: any) => {
			setUser(res.data);
		}).catch(() => {
			setError(true);
		});
	}, [match])

	return (
		<section className='verify-email'>
			{
				!user
				? error
					? <p>Something went wrong. Are you trying to verify an already verified email?</p>
					: <p>Loading...</p>
				: <p>Thanks for verifying your email, {user?.name}!</p>
			}
			<ul>
				<li><Link to='/competitions'>Competitions</Link></li>
				<li><a href='https://www.foodiespicks.com'>Store</a></li>
			</ul>
			<ProductTile />
		</section>
	);
}