import React, {
    useRef,
    useEffect,
    useState,
    useContext
} from 'react';
import './App.scss';
import './LoginDialog.scss';
import axios from 'axios';

import dialogPolyfill from 'dialog-polyfill'; // this is actually used in useEffect

import { config } from "./environment";
import { UserContext } from './UserContext';

const isEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return emailRegex.test(email);
}

export function LoginDialog({ open, setOpen }: any) {
    const [, setUserState] = useContext(UserContext);
    const dialog = useRef(null) as any;
    const [isLogin, setIsLogin] = useState(true);
    const [registered, setRegistered] = useState(false);
    const [state, setState] = useState({} as any);
    const [error, setError] = useState(null as any);

    useEffect(() => {
        const dialogs = document.querySelectorAll('dialog');
        dialogs.forEach(dialog => {
            dialogPolyfill.registerDialog(dialog);
        });
    });

    if (open) {
        if (dialog.current && !dialog.current.open) {
            dialog.current.showModal();
        }
    } else if (dialog.current && dialog.current.open) {
        dialog.current.close();
    }

    const switchIsLogin = (isLogin: boolean) => {
        setIsLogin(isLogin);
        setOpen(false);
        setTimeout(() => setOpen(true));
    }

    const logIn = () => {
        const user = {...state};
        axios.post(`${config.backendUrl}/login`, user).then((res) => {
            setError(null);
            setUserState({
                status: 'success',
                error: null,
                user: res.data
            });
            setOpen(false);
        }).catch((error) => {
            setError(error);
            console.log(error)
        });
    }

    const register = () => {
        const user = {...state};
        axios.post(`${config.backendUrl}/register`, user).then((res) => {
            if (res.data.success) {
                setRegistered(true);
                setError(null);
                setIsLogin(true);
                setTimeout(logIn, 10000);
            }
        }).catch((error) => {
            console.log('axios error', error)
        });
    }

    const handleInputChange = (event: any) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setState({
            ...state,
            [name]: value
        });
    }

    return (
        <>
            <dialog ref={dialog}>
                {isLogin
                    ? <>
                        {/**************** LOGIN *****************/}
                        <h2 className="modal-header">Login</h2>
                        <div className="modal-body">
                            {
                                registered
                                && <p>
                                    Thanks for registering!<br /><br />
                                    We've sent you an email to confirm that you really own this email address.<br />
                                    Check your inbox and open the verification link.<br /><br />
                                    We will now log you in 😊
                                </p>
                            }
                            {
                                error
                                && <>
                                    <p>Incorrect username or password.</p>
                                    <p>Have you registered?</p>
                                </>
                            }
                            <input
                                type="email"
                                name="email"
                                placeholder="foodie@foodiespicks.com"
                                value={state.email || ""}
                                onChange={handleInputChange}
                            /><br />
                            <input
                                type="password"
                                name="password"
                                placeholder="password"
                                value={state.password || ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <footer className="modal-footer">
                            <a href="" onClick={(event: any) => { event.preventDefault(); switchIsLogin(false); }}>Register</a>
                            <button
                            disabled={ !state.email || !state.password }
                            style={{ float: 'right' }}
                            onClick={logIn}>
                                Log in
                            </button>
                        </footer>
                        <button className="close" type="button" onClick={() => setOpen(false)}>&times;</button>
                    </>
                    : <>
                        {/******************** REGISTER *******************/}
                        <h2 className="modal-header">Register</h2>
                        <div className="modal-body">
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={state.name || ""}
                                onChange={handleInputChange} /><br />
                            <input
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                value={state.lastName || ""}
                                onChange={handleInputChange} /><br />
                            <input
                                type="email"
                                name="email"
                                placeholder="foodie@foodiespicks.com"
                                value={state.email || ""}
                                onChange={handleInputChange} /><br />
                            <input
                                type="password"
                                name="password"
                                placeholder="password"
                                value={state.password || ""}
                                onChange={handleInputChange} /><br />
                            <input
                                type="password"
                                name="confirmPassword"
                                placeholder="confirm password"
                                value={state.confirmPassword || ""}
                                onChange={handleInputChange} /><br />
                            { state.password !== state.confirmPassword
                            && <p>*Passwords don't match</p>}
                            <input
                                type="checkbox"
                                name="marketing" 
                                id="marketing"
                                value={state.marketing || ""}
                                onChange={handleInputChange} />
                            <label htmlFor="marketing">I want to receive updates and offers</label><br />
                            <input
                                type="checkbox"
                                name="terms"
                                id="terms" 
                                value={state.terms || ""}
                                onChange={handleInputChange} />
                            <label htmlFor="terms">I have read and accept <a href="">Terms Of Service</a> and <a href="">Privacy Policy</a></label>
                        </div>
                        <footer className="modal-footer">
                            <a href="" onClick={(event: any) => { event.preventDefault(); switchIsLogin(true); }}>Login</a>
                            <button
                            disabled={
                                !state.name
                                || !state.email
                                || !isEmail(state.email)
                                || !state.password
                                || !state.confirmPassword
                                || state.password !== state.confirmPassword
                                || !state.terms
                            }
                            style={{ float: 'right' }}
                            onClick={register}>
                                Register
                            </button>
                        </footer>
                        <button className="close" type="button" onClick={() => setOpen(false)}>&times;</button>
                    </>}
            </dialog>
        </>
    );
}