import React, { useState } from 'react';
import useAxios from 'axios-hooks';
import 'medium-draft/lib/basic.css';
import './App.scss';
import './Competition.scss';

import { config } from "./environment";
import { useRouteMatch, Link } from 'react-router-dom';
import { ProductTile } from './ProductTile';

export function Competition() {
    const match = useRouteMatch("/competition/:competitionId");
    const [descriptionExpanded, setDescriptionExpanded] = useState(true);
    const [{ data, loading, error }, refetch] = useAxios(
        `${config.apiUrl}${match?.url}`
    );

    const now = new Date();
    const dateEntriesStart = data && data.dateEntriesStart ? new Date(data.dateEntriesStart) : now;
    const dateEntriesEnd = data && data.dateEntriesEnd ? new Date(data.dateEntriesEnd) : now;
    const dateVotingStarts = data && data.dateVotingStarts ? new Date(data.dateVotingStarts) : now;
    const dateVotingEnds = data && data.dateVotingEnds ? new Date(data.dateVotingEnds) : now;

    const votingInProgress = dateVotingStarts < now && dateVotingEnds > now;
    const entriesInProgress = dateEntriesStart < now && dateEntriesEnd > now;
    const isUpcoming = dateEntriesStart > now;
    const ended = dateVotingEnds < now;

    // TODO show something for ended competitions

    return (
        <section className='competition'>
            {data && <>
                <h1>{data.name}</h1>
                <div className='competition-dates'>
                    Entries: {dateEntriesStart.toDateString()} - {dateEntriesEnd.toDateString()}<br />
                    Voting: {dateVotingStarts.toDateString()} - {dateVotingEnds.toDateString()}
                </div>
                {entriesInProgress &&
                    <Link className='button-enter' to={`/competition/${data.id}/enter`}>
                        Enter the competition!
                    </Link>
                }
                {votingInProgress &&
                    <Link className='button-vote' to={`/competition/${data.id}/vote`}>
                        Vote!
                    </Link>
                }
                <div className={`description-wrapper${descriptionExpanded ? ' expanded' : ''}`}>
                    <div dangerouslySetInnerHTML={{ __html: data.description }} />
                    <button className='show-more' onClick={() => setDescriptionExpanded(!descriptionExpanded)}>
                        Show {descriptionExpanded ? 'less' : 'more'}
                    </button>
                </div>
                <ProductTile />
                {
                    data.entries && data.entries.length
                    && <h2>Entries</h2>
                }
                {data.entries.sort((a: any, b: any) => b.votes.length - a.votes.length)
                    .map((entry: any) =>
                        <div className='entry-wrapper'>
                            <span className='votes'>Votes<br />{entry.votes.length}</span>
                            <Link to={`/competition/${data.id}/vote/${entry.id}`} key={entry.id} className='entry'>
                                <h3>{entry.title}</h3>
                                <div className='entry-content'>
                                    <img
                                        className="entry-image"
                                        src={`${config.backendUrl}/entry-images/${entry.id}/330/330/featured-image.jpg`} />
                                    <div dangerouslySetInnerHTML={{ __html: entry.description }} />
                                </div>
                            </Link>
                        </div>
                    )}
            </>
            }
        </section>
    );
}