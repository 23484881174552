import React, {
	useState,
	useRef,
	useEffect,
	useContext
} from 'react';
import './App.scss';
import './Battle.scss';

import { config } from "./environment";
import { useRouteMatch, useHistory } from 'react-router-dom';
import useAxios, { configure } from 'axios-hooks';
import axios from 'axios';
import { LoginDialog } from './LoginDialog';
import { ReactComponent as WebsiteLogo } from './svg/website.svg';
import { ReactComponent as InstagramLogo } from './svg/instagram.svg';
import { ReactComponent as TwitterLogo } from './svg/twitter.svg';
import { ReactComponent as FacebookLogo } from './svg/facebook.svg';
import { ReactComponent as PinterestLogo } from './svg/pinterest.svg';
import { ProductTile } from './ProductTile';
import { UserContext } from './UserContext';

axios.defaults.withCredentials = true;
const axiosInstance = axios.create({
	withCredentials: true,
	baseURL: config.apiUrl
});

configure({ axios: axiosInstance } as any);

const websiteLink = (link: string) => {
	if (link.toLowerCase().startsWith('http')) {
		return link;
	}
	return `http://${link}`;
}

const socialMediaUsername = (username: string) => {
	if (username.startsWith('@')) {
		return username.slice(1);
	}

	if (username.toLowerCase().startsWith('http://') || username.toLowerCase().startsWith('https://')) {
		username = username.endsWith('/') ? username.slice(0, username.length - 1) : username;
		return username.substr(username.lastIndexOf('/'));
	}

	return username;
}

export const VotableEntryTile = ({ entry, vote, expanded, hidden, expand, votingInProgress }: any) => {
	const didDoubleClick = useRef(false);

	// the dance with do* functions and didDoubleClick is needed
	// to not trigger a single tap twice when double tapping
	const doVote = () => {
		didDoubleClick.current = true;
		if(window.getSelection) {
			window.getSelection()?.removeAllRanges();
		}
		vote(entry.id);
	};

	const doExpand = (expanded: boolean) => {
		didDoubleClick.current = false;
		setTimeout(() => {
			if (!didDoubleClick.current) {
				expand(expanded);
			}
		}, 250);
	};

	return <div className='battle-entry-wrapper'>
		{
			expanded && votingInProgress
			&& <button className='button-vote' onClick={doVote}>Vote</button>
		}
		<div
			className={`battle-entry${hidden ? ' hidden' : ''}${expanded ? ' expanded' : ''}`}
			onDoubleClick={doVote}
			onClick={() => doExpand(expanded ? null : entry)}>
			{expanded && <button className="close" type="button" onClick={() => expand(null)}>&times;</button>}
			<h2>{entry.title}</h2>
			{
				expanded && entry.author
				&& <div className='author-info'>
					{entry.author.name} {entry.author.lastName} <br />
					{
						entry.author.website &&
						<a href={websiteLink(entry.author.website)} target='_blank' rel='noopener noreferrer'><WebsiteLogo /></a>
					}
					{
						entry.author.instagram &&
						<a href={`https://www.instagram.com/${socialMediaUsername(entry.author.instagram)}`} target='_blank' rel='noopener noreferrer'><InstagramLogo /></a>
					}
					{
						entry.author.twitter &&
						<a href={`https://www.twitter.com/${socialMediaUsername(entry.author.twitter)}`} target='_blank' rel='noopener noreferrer'><TwitterLogo /></a>
					}
					{
						entry.author.facebook &&
						<a href={`https://www.facebook.com/${socialMediaUsername(entry.author.facebook)}`} target='_blank' rel='noopener noreferrer'><FacebookLogo /></a>
					}
					{
						entry.author.pinterest &&
						<a href={`https://www.pinterest.com/${socialMediaUsername(entry.author.pinterest)}`} target='_blank' rel='noopener noreferrer'><PinterestLogo /></a>
					}
				</div>
			}
			<div className="battle-image">
				<img src={`${config.backendUrl}/entry-images/${entry.id}/600/800/featured-image.jpg`} />
			</div>
			<div
				className={`description ${!expanded ? 'hidden' : ''}`}
				dangerouslySetInnerHTML={{__html: entry.description}} />
		</div>
	</div>
};


export const Battle = () => {
	const match = useRouteMatch([
		"/competition/:competitionId/vote/:entryId",
		"/competition/:competitionId/vote"
	]);
	const history = useHistory();
	const [shouldShowLoginDialog, setShouldShowLoginDialog] = useState(false);
	const [shouldShowThankYou, setShouldShowThankYou] = useState(false);
	const [expandedEntry, setExpandedEntry] = useState(null as any);
	const [votingError, setVotingError] = useState(null as any);
	const [{user}] = useContext(UserContext);

	const [{ data, loading, error }, refetch] = useAxios(
		`competition/${(match?.params as any).competitionId}/entry-pair${(match?.params as any).entryId ? `/${(match?.params as any).entryId}` : ''}`
	);

	const [{ data: competitionData }] = useAxios(`competition/${(match?.params as any).competitionId}`);

	const [{ data: galleryData }, refetchGallery] = useAxios(`competition/${(match?.params as any).competitionId}/random/10`, { manual: true });

	const battleStartRef = React.createRef<any>();

	const now = new Date();
    const dateVotingStarts = competitionData && competitionData.dateVotingStarts ? new Date(competitionData.dateVotingStarts) : now;
    const dateVotingEnds = competitionData && competitionData.dateVotingEnds ? new Date(competitionData.dateVotingEnds) : now;

    const votingInProgress = dateVotingStarts < now && dateVotingEnds > now;

	const fetchNewPair = () => {
		if ((match?.params as any).entryId) {
			history.push(`/competition/${(match?.params as any).competitionId}/vote`)
		} else {
			refetch();
		}
	};

	useEffect(() => {
		battleStartRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
	}, [(match?.params as any).competitionId, (match?.params as any).entryId]);

	useEffect(() => {
		// fetch gallery with a delay so the important data loads fast first
		setTimeout(() => {
			refetchGallery();
		}, 3000);
	}, [refetchGallery]);

	useEffect(() => {
		if (data && !loading) {
			setShouldShowThankYou(false);
		}
	}, [data, loading]);

	const vote = (entryId: string) => {
		if (!votingInProgress) {
			return;
		}

		axiosInstance.post(
			`competition/${(match?.params as any).competitionId}/vote`,
			{
				pickedId: entryId,
				choices: data.map((entry: any) => entry.id)
			}
		).then((response) => {
			console.log("voted", response);
			setShouldShowThankYou(true);
			setVotingError(null); // no error
			setExpandedEntry(null); // deselect everything
			// TODO something with the response

			// Allow 'Thank You' to be displayed for a bit
			setTimeout(fetchNewPair, 1500);
		}).catch((error) => {
			console.log("caught", error.response);
			setVotingError(error.response);
			if (error.response.status === 401) {
				setShouldShowLoginDialog(true);
				return;
			}
		});
	};

	return (
		<section className='battle'>
			{
				votingError && votingError.data && !votingError.data.success
				&& <div style={{textAlign: 'center', color: 'darkred', fontWeight: 'bold'}}>
					{votingError.data.message}
				</div>
			}
			<h2 style={{textAlign: 'center'}} ref={battleStartRef}>{user && `${user.name}!`} Pick One 😉</h2>
			<div style={{textAlign: 'center', fontStyle: 'italic'}}>
				<p>Tap to see more about the entry</p>
				{ votingInProgress
					&& <p>Doubletap to vote</p>
				}
			</div>
			{
				data
					? <div className="battle-wrapper">
						<VotableEntryTile
							entry={data[0]}
							vote={vote}
							expanded={expandedEntry && expandedEntry.id === data[0].id}
							hidden={expandedEntry && expandedEntry.id !== data[0].id || loading}
							votingInProgress={votingInProgress}
							expand={setExpandedEntry} />
						{
							!expandedEntry
							&& <div className='or-element'>
								or
								<button
								className='button-new-pair'
								onClick={fetchNewPair}>
									&#x21bb;
								</button>
							</div>
						}
						<VotableEntryTile
							entry={data[1]}
							vote={vote}
							expanded={expandedEntry && expandedEntry.id === data[1].id}
							hidden={expandedEntry && expandedEntry.id !== data[1].id || loading}
							votingInProgress={votingInProgress}
							expand={setExpandedEntry} />
					</div>
					: <span />
			}
			<ProductTile />
			{
				galleryData
				&& <>
					<h2 style={{textAlign: 'center'}}>Other Entries</h2>
					<div className='gallery'>
						{galleryData.map((entry: any) => (
							<a
							className='gallery-entry'
							onClick={(event) => {
								event.preventDefault();
								battleStartRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
								setTimeout(() => history.push(`/competition/${(match?.params as any).competitionId}/vote/${entry.id}`), 300);
							}}
							href={`/competition/${(match?.params as any).competitionId}/vote/${entry.id}`}>
								<img src={`${config.backendUrl}/entry-images/${entry.id}/190/190/featured-image.jpg`} />
							</a>
						))}
					</div>
				</>
			}
			{
				shouldShowThankYou
				&& <div className='thank-you wut'>Thank You<br />for voting!</div>
			}
			<LoginDialog open={shouldShowLoginDialog} setOpen={setShouldShowLoginDialog} />
		</section>
	);
}