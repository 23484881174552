import React, { useState, useContext, useEffect } from 'react';
import { convertToRaw } from 'draft-js';
import {
	Editor,
	createEditorState
} from 'medium-draft';
import mediumDraftImporter from 'medium-draft/lib/importer';
import mediumDraftExporter from 'medium-draft/lib/exporter';
import 'medium-draft/lib/index.css';

import axios from 'axios';

import './App.scss';
import './UserProfile.scss';

import { config } from "./environment";
import { LoginDialog } from './LoginDialog';
import { UserContext } from './UserContext';

axios.defaults.withCredentials = true;
const axiosInstance = axios.create({
	withCredentials: true,
	baseURL: config.apiUrl
});

export const UserProfile = () => {
	const [{ user }, setUserState] = useContext(UserContext);
	const [state, setState] = useState({} as any);
	const [changesMade, setChangesMade] = useState(false);
	const [shouldShowLoginDialog, setShouldShowLoginDialog] = useState(false);
	const [editorState, doSetEditorState] = useState(createEditorState());
	const refsEditor = React.createRef();

	useEffect(() => {
		setState(user);
		if (user && user.bio) {
			doSetEditorState(createEditorState(convertToRaw(mediumDraftImporter(user.bio))));
		}
	}, [user]);

	const setEditorState = (es: any) => {
		setChangesMade(true);
		doSetEditorState(es);
	};

	const handleInputChange = (event: any) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		setChangesMade(true);
        setState({
            ...state,
            [name]: value
        });
	}
	
	const updateUserProfile = () => {
		axiosInstance.post(`${config.apiUrl}/user`, {
			...state,
			bio: mediumDraftExporter(editorState.getCurrentContent())
		}).then(res => {
			setUserState({ // update user with fresh data
				status: 'success',
				error: null,
				user: res.data
			});
			setChangesMade(false); // saved, reset state
		}).catch(error => {
			console.log(error.response);
			if (error.response.status === 401) {
				setShouldShowLoginDialog(true);
			}
			// TODO handle other statuses
		});
	};

	return (
		<>
			{
				state
				&& <section className='user-page'>
					<h2>My Profile</h2>
					<input
						type='text'
						name='name'
						placeholder='Name'
						value={state.name || ''}
						onChange={handleInputChange} /><br />
					<input
						type='text'
						name='lastName'
						placeholder='Last Name'
						value={state.lastName || ''}
						onChange={handleInputChange} /><br />
					<input
						type='text'
						name='website'
						placeholder='Your Website Link'
						value={state.website || ''}
						onChange={handleInputChange} /><br />
					<input
						type='text'
						name='instagram'
						placeholder='Instagram Username'
						value={state.instagram || ''}
						onChange={handleInputChange} /><br />
					<input
						type='text'
						name='twitter'
						placeholder='Twitter Username'
						value={state.twitter || ''}
						onChange={handleInputChange} /><br />
					<input
						type='text'
						name='facebook'
						placeholder='Facebook Username'
						value={state.facebook || ''}
						onChange={handleInputChange} /><br />
					<input
						type='text'
						name='pinterest'
						placeholder='Pinterest Username'
						value={state.pinterest || ''}
						onChange={handleInputChange} /><br />
					<Editor
						ref={refsEditor}
						editorState={editorState}
						onChange={setEditorState}
						className='entry-editor'
						placeholder='Tell foodies something about yourself'
						sideButtons={[]} />

					<button
					disabled={!changesMade}
					onClick={updateUserProfile}>
						Save
					</button>
				</section>
			}
			<LoginDialog open={shouldShowLoginDialog || !user} setOpen={setShouldShowLoginDialog} />
		</>
	);
}